<template>
  <div>
    <div style="clear: both; height: 70px">
      <!-- height: 210px; -->
      <document-header
        :docStatusType="docStatusType"
        :templateType="templateType"
      ></document-header>
      <div class="doc-list">
        <!-- {{ "List of " + docStatusType + " documents" }} -->
        {{ "List of " + docStatusType + " " + templateType }}
      </div>
      <div class="container-fluid mt-3">
      <div class="row">
        <div class="col">
          <div class="card shadow1">
            <div class="card-body">
              <div class="table-responsive">
                <base-table
                  class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData"
                >
                  <template slot="columns">
                    <th v-if="docStatusType.toLowerCase() === 'batch'" class="sharedHeader">Batch Number</th>
                    <th>Title</th>
                    <th v-if="docStatusType.toLowerCase() === 'shared'" class="sharedHeader">Shared To</th>
                    <th>Status</th>
                    <th>Created By</th>
                  </template>

                  <template slot-scope="{row}">
                    <td v-show="batchfound">
                      <div class="avatar-group">
                        <span class="status">{{row.batchNumber}}</span>
                      </div>
                    </td>
                    <td v-on:dblclick="handleRowClick(row)">
                      <div class="avatar-group">
                        <span class="status" v-html="row.title"></span>
                      </div>
                    </td>
                    <td v-if="docStatusType.toLowerCase() === 'shared'" > 
                      <div class="avatar-group" :title="row.info.email">
                        <span class="sharedRow" :title="row.info.email">{{row.info.email}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.status1}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.createdBy}}</span>
                      </div>
                    </td>
                  </template>
                </base-table>
              </div>
              <div
                class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''"
              >
                <base-pagination :per-page="5" :total="tableData.length"></base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import documentHeader from "@/views/process/documentsv1/documentHeader";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";

import axios from "../../utils/axiosinstance";
// import documentList from "./../../../sample-data/document-list.json";


Vue.use(VueClipboard);
export default {
  components: { documentHeader },
  props: ['type',],
  // watch: { 
  //         docStatusType: function() { 
  //         this.loadDocument();
  //         }
  // },
  directives: {
    "b-tooltip": BTooltipDirective
  },
  data() {
    return {
      tableData: [],
      batchfound: false,
      docStatusType: "",
      industryItemType: "",
      industryItemID: "",
      templateType: "document",
      // tableData: documentList
    }
  },
  beforeMount() {
    this.templateType = this.$route.query.templateType;
    this.docStatusType = this.$route.query.docStatusType;
  },
  mounted() {
    this.loadDocument();
  },
  methods: {
    loadDocument()
    {
      if(this.docStatusType.toLowerCase()  == "batch")
      {
        this.batchfound = true;
      }
      else
      {
        this.batchfound = false;
      }

      let authCookieObj = this.getCookie("auth-token");
      //console.log(authCookieObj);
      // let authObj = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if( authCookieObj.startsWith("j:"))
      {
        authObj = JSON.parse(decodeURIComponent(this.getCookie("auth-token").substring(2).split(';')[0]));
      }
      else{
       authObj = JSON.parse(decodeURIComponent(authCookieObj.split(';')[0]));
      }
      if(authObj.departments && authObj.departments[0] ){
        var docListURL;
      docListURL = "/api/organizations/" +
        authObj.orgs[0].orgID +
        "/" +
        this.templateType +
        "?status1=" +
        this.docStatusType.toLowerCase();
      if( this.docStatusType.toLowerCase()  == "batch" ||  this.docStatusType.toLowerCase()  == "live" ||  this.docStatusType.toLowerCase()  == "archived" )
        docListURL = "/api/organizations/" + authObj.orgs[0].orgID + "/batches?status1=" + this.docStatusType.toLowerCase();
      axios
        .get(docListURL)
        .then(response => {
          // console.log(response);
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
      else{
        docListURL = "/api/organizations/" +
        authObj.orgs[0].orgID +
        "/" +
        this.templateType +
        "?status1=" +
        this.docStatusType.toLowerCase();
      if( this.docStatusType.toLowerCase()  == "batch" ||  this.docStatusType.toLowerCase()  == "live" ||  this.docStatusType.toLowerCase()  == "archived" )
        docListURL = "/api/organizations/" + authObj.orgs[0].orgID + "/batches?status1=" + this.docStatusType.toLowerCase();
      axios
        .get(docListURL)
        .then(response => {
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
    },
    // handleRowClick(item) {
    //   console.log(item);
    //   let app = this;
    //   if( this.docStatusType.toLowerCase().startsWith("batch") || 
    //       this.docStatusType.toLowerCase().startsWith("live") || this.docStatusType.toLowerCase().startsWith("archived") )
    //   {
    //     app.$router.push({
    //       name: "batch",  
    //       params: { docid: item.batchID, docStatusType: this.docStatusType.toLowerCase()  }
    //     });
    //   }
    //   else{
    //       app.$router.push({
    //       name: "new",
    //       params: { docid: item.docID, docStatusType: this.docStatusType.toLowerCase() }
    //     });
    //   }
    // },
    handleRowClick(item) {
      // let item = row.data;
      if (
        this.docStatusType.toLowerCase().startsWith("batch") ||
        this.docStatusType.toLowerCase().startsWith("live") ||
        this.docStatusType.toLowerCase().startsWith("archived")
      ) {
        window.location.href =
          "/batch?batchID=" +
          item.batchID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&templateType=" +
          this.templateType;
      } else {
        window.location.href =
          "/new?docid=" +
          item.docID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&templateType=" +
          this.templateType;
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    }
    // onCopy() {
    //   this.$notify({
    //     type: "success",
    //     title: "Copied to clipboard"
    //   });
    // }
  }
};
</script>
<style>
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.doc-list {
  font-weight: 700;
  top: 10px;
  position: relative;
  margin-left: 45px;
  text-align: center;
  /* background-color: #ffff;
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px); */
}
html {
  overflow-x: hidden;
}
</style>