<template>
  <div>
    <div class="row">
      <div
        v-if="templateType && templateType != 'undefined' && templateType != 'null'"
        class="pdf-heading"
      >
        {{ templateType }}
      </div>
      <!-- <div class="title_heading">
        {{ templateType ="document" }}
      </div> -->
      <div id="myDIv"
          v-if="!isSharedPage"
        >
        <a :href="onHeaderClick('batch')">
          <button :class="{btnactive:docStatusType === 'batch' }" class="btnn">
            <i class="ni ni-user-run i-golive"></i><span style="margin-left: 5px;">BATCH RUNS</span>
          </button>
        </a>
        <a :href="onHeaderClick('new')">
          <button :class="{btnactive:docStatusType === 'new'}" class="btnn btnwidth">
            NEW 
          </button>
        </a>
        <a :href="onHeaderClick('draft')">
          <button :class="{btnactive:docStatusType === 'draft'}" class="btnn btnwidth">
            DRAFTS
          </button>
        </a>
        <a :href="onHeaderClick('approved')">
          <button :class="{btnactive:docStatusType === 'approved'}" class="btnn btnwidth">
            APPROVED
          </button>
        </a>
        <a :href="onHeaderClick('archived')">
          <button :class="{btnactive:docStatusType === 'archived'}" class="btnn btnwidth">
            ARCHIVED
          </button>
        </a>
        <a :href="onHeaderClick('versionup')">
          <button :class="{btnactive:docStatusType === 'versionup'}" class="btnn btnwidth">
            VERSION UP
          </button>
        </a>
        <a :href="onHeaderClick('shared')">
          <button :class="{btnactive:docStatusType === 'shared'}" class="btnn btnwidth">
            SHARED
          </button>
        </a>
        </div>
    </div>
  </div>
  
</template>
<script>
// import Vue from "vue";

export default {
  name: "document-header",
  data() {
    return {
      docStatusType: "draft",
      templateType: "",
      isSharedPage: false,
      equipmentTemp: {},
      sampleManagementTemp: {},
      deviationTemp: {},
      capaTemp: {},
      reagentTemp: {},
      authObj: {},
    };
  },
  beforeMount(){
    this.docStatusType = this.$route.query.docStatusType;
    this.templateType = this.$route.query.templateType;
    if (window.location.href.toLowerCase().includes("/shareddocument")) {
      this.isSharedPage = true;
    }
    // this.docStatusType = this.$route.query.docStatusType;
    // if(!this.docStatusType && this.$route.path) {
    //   var routePath = this.$route.path;
    //   this.docStatusType = routePath.substring(routePath.lastIndexOf('/')+1)
    // }
    // this.templateType = this.$route.query.templateType;
    // this.industryItemType = this.$route.query.industryItemType;
    // this.industryItemID = this.$route.query.industryItemID;
    // if (window.location.href.toLowerCase().includes('/shareddocument')){
    //   this.isSharedPage = true;
    // }
  },
  methods: {
    // onHeaderClick(activeBtn) {
    //   document.getElementById("myDocument").getElementsByClassName("btnactive")[0].classList.remove("btnactive");
    //   document.getElementById(activeBtn+"Button").classList.add("btnactive");
    //   return true;
    // },
    onHeaderClick(docStatusType) {
      if (docStatusType == "new") {
        return "/new?docStatusType=" + docStatusType + "&templateType=document";
      }
      return "/documents?docStatusType=" + docStatusType + "&templateType=document";
    },
    openShare() {
      var header = document.getElementById("myDocument");
      var btns = header.getElementsByClassName("btnn");
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("btnactive");
        current[0].className = current[0].className.replace("btnactive", "");
        this.className += "btnactive";
        });
      }
    },
    isMobileandTab() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  }
};
</script>
<style>
html {
  overflow-x: hidden;
}
.custom-font {
  font-size: 8px;
}
.btnn{
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  transition: all .15s ease!important;
  letter-spacing: .025em!important;
  font-size: .775rem!important;
  box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
  font-weight: 500!important;
  border: 1px solid #a2ccf9!important;
  padding: 0.625rem 1.25rem!important;
  line-height: 1.5!important;
  border-radius: 0.375rem!important;
  color: #172b4d!important;
  border-color: #dadce0!important;
}
.btnn.btnwidth {
  width: 130px;
}
.btnn a{
  color: #000;
}.btnn a:hover{
  color: #000;
}
.btnactive, .btnn:hover {
  background-color: #2dce89;
}
#myDIv{
  margin-top: 25px;
  margin-left: 15px;
}
.i-golive {
  color: #08215d;
}
.pdf-heading {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: #00aff0;
  border-radius: 10px;
  padding: 8px 15px;
  margin: 25px 5px 5px 18px;
  }
.equipment-toggle {
  background-color: #80808024;
  margin-top: 15px;
  text-align: center;
}
label.form-control-label.equipment {
  margin-left: 10px;
}
</style>